import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyCancelarFactura: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Cancelar facturas
            </h2>
            <p>
                Las cancelaciones son gratuitas, las dos formas de cancelar una factura es desde la vista de detalle del pedido o desde la sección de "Mis facturas".
            </p>
            <h3>
                Cancelar desde detalle de pedido
            </h3>
            <div className="row two centered">
                <ImageFooted explanation={`Puedes cancelar facturas desde la vista de detalle de cada pedido. Cuando la factura es individual al pedido (no Global), puedes cancelarla gratuitamente con seguridad`}>
                    <IMG_Shopify file={'cancelar'} />
                </ImageFooted>
                <ImageFooted explanation={`Los pedidos que se incluyen en una factura global muestran la opción de "Emitir a cliente". Cuidado al cancelar una factura global desde esta vista, se cancela toda la factura, no extrae el pedido de la factura.`}>
                    <IMG_Shopify file={'cancelarGlobal'} />
                </ImageFooted>
            </div>
            <h3>
                Cancelar desde "Mis Facturas"
            </h3>
            <p>
                En esta sección podrás cancelar cualquier tipo de factura, incluyendo las notas de crédito y faturas globales. 
            </p>
            <ImageFooted explanation={`Desde la vista principal de Facturas, puedes buscar la factura a cancelar y usar el ícono de basura para cancelarla.`}>
                <IMG_Shopify file={'cancelarFacturas'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/mis-clientes`} next={`/ecommerce/shopify/reporte-facturas`}/>
        </div>
    </Layout>
)

export default ShopifyCancelarFactura;